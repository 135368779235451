import './Gauge.css';
import { Typography } from '@material-ui/core'
import useStyles from './GaugeStyles'

export default function Gauge(props) {
    const classes = useStyles();

    return (
        <div className="gauge_container">
            <div className="needle_container">
                <div className="needle" style={{ "--r": props.percentageCompleted }} ></div>
            </div>
            <Typography className={classes.progressText} variant="h4" align="center" color="primary">{Math.trunc(props.percentageCompleted)}%</Typography>
        </div>
    )
}
