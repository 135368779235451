import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Container } from '@material-ui/core'
import useStyles from './CompletedProjectsContainerStyles'

function CompletedProjectsContainer(props) {

    const classes = useStyles();

    return (
        <Container className={classes.completedProjectsContainer} maxWidth={false}>
        <Typography variant="h5" className={classes.completedProjectsTitle}> <b>Completed projects: </b>
          {
            props.completedProjectNames.map(projectName =>
              <span key={projectName} className={classes.completedProjectsProjectName}>{projectName}</span>
            )
          }
        </Typography>
      </Container>
    )
}

CompletedProjectsContainer.propTypes = {
    completedProjectNames: PropTypes.array
}

export default CompletedProjectsContainer

