import React from 'react';
import Lottie from 'lottie-web';
import style from './simple-lottie-animation.module.css';

/**
 * @class SimpleLottieAnimation
 * @description Wrapper for Lottie animations
 */
export default class SimpleLottieAnimation extends React.Component {
    
    /**
     * @constructor SimpleLottieAnimation
     * @param { Object } props The only required parameter is animation, the src for the Lottie data
     */
    constructor(props) {
        super(props);
        this.animationContainer = React.createRef();
        this.play = this.play.bind(this);
        this.stop = this.stop.bind(this);
        this.pause = this.pause.bind(this);
        this.isPlaying = false;
        this.animationObserver = new IntersectionObserver(entries => {
            const entry = entries[0];
            if (entry.intersectionRatio > 0.75) {
                this.play();
            } else {
                this.pause();
            }
        }, {
            root: null,
            threshold: 0.75
        });
    }
    
    componentDidMount() {
        this.animationInstance = Lottie.loadAnimation({
            container: this.animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: !!this.props.autoplay,
            animationData: this.props.animation,
        });
        this.animationObserver.observe(this.animationContainer.current);
    }

    play() {
        this.animationInstance && this.animationInstance.play();
        this.isPlaying = true;
    }

    stop() {
        this.animationInstance && this.animationInstance.stop();
        this.isPlaying = false;
    }

    pause() {
        this.animationInstance && this.animationInstance.pause();
        this.isPlaying = false;
    }
    
    render() {
        const containerClass = this.props.containerClass || style.container;
        return (<div className={containerClass} ref={this.animationContainer} />);
    }
}
