import React, { useEffect, useState, useRef } from 'react';
import { getDetails } from '../../services/getdetails';

import DashboardHeader from './DashboardHeader'
import CompletedProjectsContainer from '../Containers/CompletedProjectsContainer'
import OngoingProjectsContainer from '../Containers/OngoingProjectsContainer'
import { Typography, CssBaseline, Paper, Box, Button } from '@material-ui/core'
import useStyles from './DashboardStyles'
import SimpleLottieAnimation from '../Lottie/simple-lottie-animation'
import * as spinner from "../../lottie/vf_spinner_red_small_75.json";

function Dashboard() {
  const [totalDreamers, setTotalDreamers] = useState(undefined);
  const [totalCompleted, setTotalCompleted] = useState(undefined);
  const [completedProjectNames, setCompletedProjectNames] = useState([]);
  const [ongoingProjects, setOngoingProjects] = useState([]);
  const [ongoingProjectsPhase, setOngoingProjectsPhase] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [getDetailsCompleted, setGetDetailsCompleted] = useState(undefined);
  const [getDetailsError, setGetDetailsError] = useState(undefined);

  const classes = useStyles();
  const updateTriggerMs = 600000; //ten minutes
  const isInitialMount = useRef(true);

  const refreshPage = ()=>{
    window.location.reload();  
  }

  useEffect(() => {
    let mounted = true;
    let updateTriggerTimeout;

    if (isInitialMount.current) {
      setInterval(() => {
        updateTriggerTimeout = setUpdateTrigger(updateTrigger => updateTrigger + 1);
      }, updateTriggerMs);
      isInitialMount.current = false;
    }

    getDetails()
      .then((details) => {
        if (!details) {
          if(updateTrigger === 0){ //Show error message only if first data retrieval
            setGetDetailsError(true)
          }          
        } else {
          setGetDetailsError(false)
          if (mounted) {
            var completedProjectNames_ = [];
            var ongoingProjects_ = [];
            var ongoingProjectsPhase_ = [];

            details.projects.forEach(project => {
              if (project.percentageCompleted === "100") {
                completedProjectNames_.push(project.projectName);
              } else {
                var phase = project.projectName.match(/\(([^)]+)\)/)
                ongoingProjectsPhase_.push(phase ? phase[1] : "")
                if(phase) { //Remove it from project name
                  project.projectName = project.projectName.replace(/ *\([^)]*\) */g, "");
                }
                ongoingProjects_.push(project)
              }
            })
            setTotalDreamers(details.totalDreamers)
            setTotalCompleted(details.totalCompleted)
            setCompletedProjectNames(completedProjectNames_)
            setOngoingProjects(ongoingProjects_)
            setOngoingProjectsPhase(ongoingProjectsPhase_)
          }
        }
        setGetDetailsCompleted(true)
      })

    return () => {
      mounted = false;
      clearTimeout(updateTriggerTimeout);
    }
  }, [updateTrigger])

  return (
    <>
      <CssBaseline />
      <Paper className={classes.paperContainer} elevation={0}>
        {!getDetailsCompleted ? 
        (<div className={classes.spinnerContainer}><SimpleLottieAnimation animation={spinner.default} /></div>)
        : ( <>
              <DashboardHeader getDetailsError={getDetailsError} totalDreamers={totalDreamers} totalCompleted={totalCompleted}/>
              {!getDetailsError ? (
                <>
                  <OngoingProjectsContainer ongoingProjects={ongoingProjects} ongoingProjectsPhase={ongoingProjectsPhase}/>
                  <CompletedProjectsContainer completedProjectNames={completedProjectNames}/>
                </>) : (
                  <Box className={classes.errorMessage}>
                    <Typography variant="h3" className={classes.errorMessageText} align="center">Error retrieving data, please try again</Typography>
                    <Button variant="contained" color="primary" onClick={refreshPage} size="large">Reload</Button>
                  </Box>)
              }
            </>
          )}
      </Paper>
    </>
  )
}

export default Dashboard;
