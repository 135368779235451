export function getDetails() {
  return fetch('https://storage.googleapis.com/dreamlab-details/details.json')
    .then(
      response => {
        if (response.ok) {
          return response.json();
        } else {
          return null;
        }
      })
    .catch(error => {
      console.error('getdetails operation failed:', error);
      return null;
    })
}