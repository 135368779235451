import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  ongoingProjectsGridContainer: {
    flexGrow: "1",
    maxWidth: "170vh",
    margin: "auto"
  },
  projectNameBox: {
    minHeight: '74px',
    },
}))

export default useStyles;