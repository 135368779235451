import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Grid, Box } from '@material-ui/core'
import Gauge from '../Gauge/Gauge'
import useStyles from './OngoingProjectsContainerStyles'

function OngoingProjectsContainer(props) {

    const classes = useStyles();
    let i=0;

    return (
      <Grid container className={classes.ongoingProjectsGridContainer} spacing={2} alignItems="center" justify="space-evenly">
      
      {props.ongoingProjects?.map(project => (
        <Grid item key={project.projectName} className={classes.ongoingProjectsGridItem} xs={12} sm={4} md={3}>
          <Box className={classes.projectNameBox}>
            <Typography variant="h3" align="center"> {project.projectName} </Typography>
            <Typography variant="h5" align="center"> {props.ongoingProjectsPhase[i++]} </Typography>
          </Box>
          <Gauge percentageCompleted={project.percentageCompleted} partnerLogoSquare={project.partnerLogoSquare} />
        </Grid>
      ))}
    </Grid>
    )
}

OngoingProjectsContainer.propTypes = {
  ongoingProjects: PropTypes.array,
  ongoingProjectsPhase: PropTypes.array
}

export default OngoingProjectsContainer

