import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box } from '@material-ui/core'
import useStyles from './DashboardHeaderStyles'

function DashboardHeader(props) {

    const classes = useStyles();
    const internationalNumberFormat = new Intl.NumberFormat('en-US');

    return (
          <Box className={classes.totalsContainer}>
            <Typography className={classes.dashboardTitle} variant="h1" align="left">Live DreamLab dashboard</Typography>
            {!props.getDetailsError && <Typography className={classes.totalsText} variant="h5" align="left">There are now <b>{internationalNumberFormat.format(props.totalDreamers)}</b> Dreamers</Typography>}
            {!props.getDetailsError && <Typography className={classes.totalsText} variant="h5" align="left"><b>{internationalNumberFormat.format(props.totalCompleted)}</b> Total calculations performed</Typography>}
          </Box>
    )
}

DashboardHeader.propTypes = {
    getDetailsError: PropTypes.bool,
    totalDreamers: PropTypes.string,
    totalCompleted: PropTypes.string
}

export default DashboardHeader

