import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  progressText: {
    position: "absolute",
    bottom: "40%",
    width: "100%",
    fontSize: 40,
    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 40,
    },
  },
}));

export default useStyles;