import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    completedProjectsContainer: {
        alignSelf: "flex-end",
      },
      completedProjectsTitle: {
        [theme.breakpoints.down('xs')]: {
          fontSize: 18,
        },
      },
      completedProjectsProjectName: {
        whiteSpace: "nowrap",
        '&::after': {content: '", "',
        whiteSpace: "normal",
        },
        '&:last-child': {
          '&::after': {content: '""',
          },
       },
      },
}))

export default useStyles;