import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    minHeight: "100vh",
    backgroundColor: "white",
    padding: "32px",
    display: 'flex',
    flexDirection: 'column',
  },
  spinnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',
    margin: '32px',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  errorMessageText: {
    marginBottom: '32px'
  }
}));

export default useStyles;