import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  totalsContainer: {
    marginBottom: "80px",
    [theme.breakpoints.down('xs')]: {
      marginBottom: "32px",
    },  
  },
  dashboardTitle: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: "15px",
    },    
    color: "black",
  },
  totalsText: {
    color: "black",
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
}));

export default useStyles;