import Dashboard from '../Dashboard/Dashboard';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import './App.css';

const breakpoints = createBreakpoints({})

const vodafoneTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#e60000'
    },
    text: {
      secondary: '#666666'
    },
  },  
  typography: {
    h1: {
      fontSize: 90,
      fontFamily: 'VodafoneRgRegular',
      [breakpoints.down('xs')]: {
        fontSize: 48,
      },
    },
    h2: {
      fontSize: 40,
      fontFamily: 'VodafoneRgRegular',
      [breakpoints.down('sm')]: {
        fontSize: 30,
      },
    },
    h3: {
      fontSize: 30,
      fontFamily: 'VodafoneRgRegular',
      [breakpoints.down('sm')]: {
        fontSize: 26,
      },
    },
    h4: {
      fontSize: 28,
      fontFamily: 'VodafoneRgRegular',
      [breakpoints.down('sm')]: {
        fontSize: 22,
      },
    },
    h5: {
      fontSize: 24,
      fontFamily: 'VodafoneRgRegular',
    },
    h6: {
      fontSize: 18,
      fontFamily: 'VodafoneRgRegular',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={vodafoneTheme}>
      <Dashboard />
    </ThemeProvider>
  );
}


export default App;
